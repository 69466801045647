* {
  --theme-color: #000;
  --border-color: #707070;
  --text-border: #ea1f27;
  --white: #fff;
  --black: #000;
  --subtext: #606060;
  --red-font-color: #ea1f27;
}

::-webkit-scrollbar {
  width: 4px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0px solid slategrey;
}

body {
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  font-family: Comic Sans MS;
  color: var(--theme-color);
}

.all-process img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul {
  margin-bottom: 0;
}

/* header-start */
ul {
  padding-left: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}


.top-header {
  background-color: var(--theme-color);
  width: 100%;
  height: 80px;
}

.menu-haeder .navbar.parent-menu {
  background: var(--white);
  /* padding: 10px 0; */
  padding: 0;
  box-shadow: 0 3px 6px rgb(0 0 0 / 11%);
}

.bottom-menu .dropdown-item {
  color: var(--subtext);
  font-size: 15px;
}

.bottom-menu .navbar-expand-lg .navbar-nav {
  justify-content: space-between;
  width: 100%;
}

.bottom-menu .dropdown-menu {
  border: none;
  box-shadow: 0 0px 12px rgb(0 0 0 / 5%);
}

.location-icon h5 a {
  color: var(--theme-color);
}

.navbar-brand img {
  width: 100%;
  height: 100%;
  max-width: 180px;
  object-fit: cover;
}

.sc-media-icons {
  display: flex;
  align-items: center;
}

.sc-media-icons ul {
  display: flex;
  align-items: center;
}

.sc-media-icons.desktop-icons .btn-comman {
  font-size: 14px;
}

.sc-media-icons ul li a {
  background: rgb(38, 197, 38);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 13px;
  margin-right: 14px;
}

.btn-comman {
  background: rgb(38, 197, 38);;
  color: var(--white);
  font-size: 20px;
  border-radius: 80px;
  padding: 5px 13px;
}

.btn-comman:hover {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--theme-color);
}

.menu-icons {
  margin-left: 20px;
}

.open-menu {
  text-align: center;
}

.open-menu ul li a {
  font-size: 18px;
  padding-bottom: 18px;
  color: var(--white);
  display: block;
  text-align: left;
}

.header-open-menu {
  /* position: fixed; */
  background: rgb(13 54 89 / 25%);
  z-index: 99999;
  width: 100%;
  top: 0;
  bottom: 0;
  height: 100%;
  /* display: none; */
  right: 0;
}

.bottom-menu .accordion {
  display: flex;
}

.bottom-menu {
  width: 100%;
  border-top: 0.5px solid rgba(112, 112, 112, 0.12);
}

.header-sec {
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
}

.header-open-inner-sec {
  position: fixed;
  background: rgb(13 54 89 / 100%);
  z-index: 99999;
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  padding: 20px 30px 30px;
  width: 25%;
}

/* Latest CHange */
.open-menu {
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

/* Latest CHange */

a.close-btn {
  text-align: right;
  display: block;
  color: var(--white);
  font-size: 29px;
}

.mobile-icons {
  display: none;
}

.mobile-right-part {
  display: flex;
  align-items: center;
}

/* .menu-haeder {
    box-shadow: 0 5px 15px rgb(0 0 0 / 16%);
  } */

/* header-end */

/* .banner-slider {
    background-image: url(../images/Banner-Image-min.png);
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    display: flex;
    align-items: center;
  } */
ul.list-sitemap {
  margin: 0 0 0 50px;
  list-style-type: auto;
}

ul.list-sitemap>li {
  margin: 0 0 10px;
  list-style-type: disclosure-closed;
}

ul.list-sitemap>li a {
  color: #333;
}

ul.list-sitemap>li>ul {
  margin: 0 0 10px 30px;
  list-style-type: circle;
  padding: 0;
}

ul.list-sitemap>li>ul>li {
  list-style-type: circle;
  font-size: 14px;
}

.contact-us-banner img {
  width: 100%;
}

.li-font {
  color: red;
  font-weight: 800;
}

.banner-slider .carousel-item img {
  height: 100%;
  /* max-height: 600px; */
}

.banner-img {
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  padding-left: 110px;
}

.banner-img h1 {
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
}

.banner-img p {
  font-size: 16px;
  line-height: 27px;
  max-width: 600px;
  padding-top: 22px;
  font-weight: 500;
}

.directore-desk.bg-transparent.repsonsive-directore-desk.about-trans-globe-banner {
  padding: 70px 0;
}

.about-trans-globe-banner .directore-inspired-details {
  padding-top: 20px;
}

.banner-btn {
  padding-top: 30px;
}

.banner-btn a {
  border-radius: 10px;
  margin-right: 22px;
  font-size: 15px;
  padding: 7px 18px;
  font-weight: bold;
}

.btn.btn-comman.talk-opacity {
  background: transparent;
  color: var(--theme-color);
  background-color: #fff;
  border-color: var(--theme-color);
}

.btn.btn-comman.talk-opacity:hover {
  opacity: 1;
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white);
}

.title-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-sec h2 {
  font-size: 30px;
  font-weight: 500;
  border-left: 10px solid var(--text-border);
  padding-left: 13px;
}

.title-section h5 {
  font-size: 30px;
  font-weight: 500;
  border-left: 10px solid var(--text-border);
  padding-left: 13px;
}

.left-border {
  border-left: 10px solid red;
  padding-left: 13px;
  margin-bottom: 10px;
}

.title-sec a {
  font-size: 18px;
  border-bottom: 1px solid var(--theme-color);
  text-transform: capitalize;
  color: var(--theme-color);
}

.client-say {
  padding: 50px 0;
  margin: 20px 0 0 0;
}

.scholarship-sec {
  padding: 50px 0 30px;
}

.directore-desk {
  padding: 20px 0;
  background: rgb(13 54 89 / 6%);
}

.client-detail-img {
  text-align: center;
  padding-top: 20px;
}

.client-img {
  margin-bottom: -70px;
}

.client-img img {
  width: 100%;
  max-width: 100px;
}

.card-first {
  background: var(--white);
  box-shadow: 0 5px 15px rgb(0 0 0 / 16%);
  border-radius: 20px;
}

.client-details {
  padding: 95px 15px 25px;
}

.client-details h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}

.client-details span strong {
  font-size: 10px;
}
.client-details span {
  font-size: 10px;
  padding-top: 6px;
  display: block;
}

.client-say .client-details p {
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  padding: 0;
  text-align: justify;
}

.our-clients-say .client-details p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.client-details p {
  font-size: 12px;
  font-weight: 400;
  padding: 10px 0;
  line-height: 1.8;
}

.client-details a {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-border);
  text-transform: capitalize;
  border-bottom: 1px solid var(--text-border);
}

.quote-img {
  margin-top: 20px;
}

.quote-img img {
  max-width: 34px;
  opacity: 0.5;
}

.all-needs-part {
  padding-top: 30px;
  position: relative;
}

.all-needs-part .row {
  align-items: flex-start;
}


.all-need-img img {
  width: 100%;
  height: 100%;
  /* max-width: 330px; */
  object-fit: cover;
  /* border-radius: 42px; */
}

.all-need-data {
  padding: 20px;
  background: #f5f5f5;
  margin-bottom: 40px;
  height: 100%;
  text-align: center;
}

.all-need-data h3 {
  font-size: 22px;
  font-weight: 500;
 display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.all-need-data p {
  font-size: 15px;
  font-weight: 400;
  color: var(--subtext);
  line-height: 1.4;
  margin: 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.all-need-data a {
  border: 1px solid var(--theme-color);
  border-radius: 8px;
  color: var(--theme-color);
  font-size: 13px;
  font-weight: 500;
  padding: 7px 10px;
}

.all-need-data a:hover {
  background: var(--theme-color);
  color: var(--white);
}

.all-need-data span {
  font-size: 18px;
  font-weight: 500;
  padding-left: 21px;
}

.scholarship-card p {
  font-size: 20px;
  color: var(--subtext);
  font-weight: 500;
  padding: 40px 0 33px;
}

.scholarship-details {
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
}

.usa-careers-blog {
  padding-top: 50px;
}

.scholarship-details img {
  width: 100%;
  height: 100%;
  max-width: 150px;
  margin-right: 10px;
}

.scholarship-details h3 {
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
}

.scholarship-details h5 {
  font-size: 13px;
  padding-top: 5px;
  font-weight: 400;
  color: var(--subtext);
  line-height: 17px;
  text-align: left;
}

.directore-inspired-details .row {
  align-items: center;
}

.all-citys-spacing {
  padding-top: 30px;
}

.directore-img {
  width: 100%;
  text-align: center;
}

.directore-img img {
  max-width: 190px;
}

.directore-inspired-details h4 {
  font-size: 31px;
  padding-bottom: 10px;
}

.directore-inspired-details p {
  font-size: 15px;
  padding-bottom: 14px;
  color: var(--subtext);
}

.university-partners {
  padding: 40px 0 50px;
}

.university-logos img {
  padding: 23px 0;
  width: 100%;
  height: 100%;
  max-width: 132px;
  min-height: 131px;
  max-height: 75px;
  object-fit: contain;
  object-position: center;
}

/* .country-list-banner.study-board {
    background-image: url(../images/study-board.png);
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  } */
.country-list-banner.study-board img {
  width: 100%;
}

.country-banner-text h1 {
  font-size: 80px;
  font-weight: 500;
  color: var(--white);
}

.country-banner-text {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 70vh; */
}

.all-country-cards {
  padding: 50px 0;
}

.country-card-list h5 {
  color: var(--subtext);
  font-weight: 400;
  font-size: 16px;
  padding: 5px 0 20px;
  padding: 10px 0 20px;
}

.country-card-list .client-details {
  padding: 0;
}

.flag-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  min-height: 165px;
  object-fit: cover;
    width: 100%;
    height: 170px;
}

.country-name-details {
  padding: 30px 10px;
}

.apply-margin-bottom {
  margin-bottom: 30px;
}

.tab-wrapper {
  overflow: hidden;
  width: 100%;
  margin: 20px 0 30px;
}

.tab-wrapper ul{
  margin: 0;
  padding: 0;
  border-bottom: 3px solid #ea1f27;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.tab-wrapper ul li{ margin: 0 20px 0 0; }
.tab-wrapper ul li a{ padding: 10px 30px; color: #333; display: block; font-size: 18px; font-weight: bold; }
.tab-wrapper ul li.active a{color: #fff; background: #ea1f27;  }


.country-card-list .client-details h4 {
  font-size:15px;
  line-height: 1.25;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
    min-height: 55px;
}

.country-card-list .client-office-page h4 {
  min-height: initial;
}

i.fa-solid.fa-right-long {
  padding-left: 7px;
}

.study-in-canada-main {
  padding: 40px 0;
  display: flex;
  align-items: center;
}

.canada-card img {
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  border-radius: 20px;
  max-width: 400px;
}

.canada-card {
  padding-right: 95px;
}

.banner-btn h2 {
  font-size: 35px;
}

.directore-desk.why-study-canada .directore-inspired-details p {
  padding-top: 0px;
}

a.btn.download-btn {
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  background: var(--theme-color);
  padding: 8px 15px;
  border-radius: 10px;
}

a.btn.download-btn:hover {
  color: var(--theme-color);
  background: transparent;
  border-color: var(--theme-color);
}

.scholarship-sec.country-list-card .scholarship-details img {
  max-width: 46px;
}

.scholarship-sec.country-list-card .scholarship-details h3 {
  font-size: 15px;
  line-height: 23px;
}

.trending-sec .card-first img {
  width: 100%;
  height: 100%;
}

.trending-sec .client-details {
  padding: 30px 17px 10px;
  color: #000;
}

.top-universitys .client-details p {
  padding: 8px 0;
}

.rating-details {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.rating-name {
  text-align: left;
  padding-left: 20px;
}

.rating-no {
  background: var(--white);
  box-shadow: 0 0 5px rgb(0 0 0 / 16%);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 7px;
}

.rating-no img {
  width: 100%;
}

.rating-name h4 {
  font-size: 18px;
}

.rating-name h5 {
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
  color: var(--subtext);
}

.rating-user-history {
  padding: 38px 0 0 25px;
}

.top-universitys .client-details {
  padding: 95px 15px 35px;
}

.admission-req-main .scholarship-card {
  padding-top: 30px;
}

.scholarship-sec.country-list-card.admission-req-main .scholarship-details img {
  max-width: 55px;
  margin-right: 10px;
  max-height: 55px;
  object-fit: contain;
}

.post-admission {
  padding: 60px 0;
}

.visa-option {
  padding: 35px 0px 16px 30px;
}

.visa-option h3 {
  font-size: 25px;
  font-weight: 500;
}

.post-admission .swiper-button-next,
.swiper-button-prev {
  top: 40%;
}

.opportunity-text h3 {
  font-size: 20px;
}

.benefits-main-sec .benefits-define .card-first {
  background-color: rgb(221, 234, 220);
}

.benefits-main-sec.benefits-of-uk p {
  padding: 10px 18px 0;
}

.post-admission-details {
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 16%);
  padding: 34px;
  max-width: 358px;
}

.visa-option-cards .post-admission-details {
  max-width: 100%;
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
  margin-bottom: 10px;
  padding: 19px;
  min-height: 110px;
}

.visa-option-cards .post-admission-details h3 {
  text-align: center;
}

.visa-option-parent {
  padding: 30px 0 0;
}

.post-admission-details h3 {
  font-size: 21px;
  font-weight: 500;
}

.post-admission-details h4 {
  font-size: 16px;
  font-weight: 400;
  color: var(--subtext);
  padding-bottom: 4px;
}

.post-admission-details p {
  font-size: 14px;
  font-weight: 400;
  color: var(--subtext);
  text-align: center;
}

.opportunity-small-card {
  padding: 30px 0 50px;
}

.opportunity-card {
  border-radius: 10px;
  padding: 10px 13px;
  display: flex;
  align-items: center;
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
}

.opportunity-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 7px;
  margin-right: 11px;
}

.opportunity-icon img {
  max-width: 50px;
}

.opportunity-text p {
  font-size: 16px;
  font-weight: 400;
  color: var(--subtext);
  line-height: 22px;
  text-align: left;
}

.listing-career li {
  font-size: 14px;
  line-height: 23px;
}

.career-emp-service-text h3 {
  font-size: 25px;
  text-align: left;
}

.career-emp-service-text p {
  font-size: 15px;
}

.explore-flag-card {
  padding: 40px 0 60px;
}

.explore-flag-card .country-card-list .client-details h4 {
  min-height: unset;
}

.first-menu-list img {
  max-width: 160px;

}

.admision-title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 0px;
}

.how-to-apply-sec p {
  font-size: 16px;
}

.admission-tabs {
  padding-top: 20px;
}

.admision-title-span {
  padding: 10px 0px;
}

.program-course-icon {
  padding-right: 20px;
}

.program-course-icon img {
  max-width: 56px;
}

.popular-program-sec .opportunity-text p {
  font-size: 16px;
}

.university-card-bg {
  border: 1px solid rgb(13 54 89 / 20%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  min-height: 134px;
}

.university-card-bg img {
  max-width: 125px;
  padding: 12px;
}

.popular-program-sec .accordion-button {
  padding: 10px 20px;
}

.border-in-right {
  border-right: 3px solid rgb(112 112 112 / 20%);
}

.set-padding-left {
  padding-left: 26px;
}

.how-to-apply-sec.border-end-0 {
  padding-left: 20px;
}

.admission-tab-menu {
  padding-top: 30px;
}

.admission-tab-menu li {
  margin-right: 80px;
}

.admission-tab-menu .nav-link {
  padding: 0;
}

.admission-tab-menu li .nav-link {
  font-size: 21px;
  font-weight: 500;
  color: var(--subtext);
}

.admission-tab-menu li .nav-link:hover {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.admission-tab-menu li .nav-link.active {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
}

.admission-tab-menu {
  display: flex;
  align-items: center;
}

.admission-timeline .nav-tabs {
  border-bottom: none;
}

.admission-timeline .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: var(--white);
}

.blur-bg {
  max-width: 335px;
  background-color: RGBA(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  margin: auto;
  border-radius: 0 0 20px 20px;
  margin-top: -67px;
  width: 100%;
  padding: 7px 0;
}

.blur-bg h4 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
}

.blur-bg h5 {
  font-size: 14px;
  font-weight: 400;
}

.ielts-main-sec .row {
  align-items: center;
}

.ielts-main-sec img {
  width: 100%;
  max-width: 200px;
}

.ielts-main-sec p {
  font-size: 25px;
  font-weight: 500;
}

.ielts-main-sec {
  padding: 50px 0 50px;
}

.official-sec-link a {
  font-size: 18px;
  border-bottom: 1px solid var(--theme-color);
  text-transform: capitalize;
  color: var(--theme-color);
}

.official-sec-link {
  text-align: right;
}

.enrol-padding {
  margin-right: 30px;
}

.official-websites-links {
  padding: 30px 0 50px;
}

.websites-link-card {
  /* background: var(--white); */
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
  border-radius: 10px;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  padding: 15px 25px;
  margin-bottom: 20px;
  height: 100%;
  min-height: 90px;
}

.websites-link-card h3 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.websites-link-card h4 {
  font-size: 14px;
  font-weight: 400;
  padding-top: 12px;
  color: var(--border-color);
}

.websites-link-card h4 a {
  color: var(--border-color);
}

.websites-link-card h4 ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 340px;
}

.websites-link-card h4 ul li {
  list-style-type: disc;
  margin-left: 20px;
}

.download-guide img {
  width: 100%;
}

.about-the-test {
  padding-top: 40px;
}

.about-the-test h3 {
  font-size: 22px;
  font-weight: 500;
}

.about-the-test p {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  color: var(--subtext);
}

.about-the-test a {
  color: var(--text-border);
  font-weight: 500;
  font-size: 15px;
  border-bottom: 1px solid var(--text-border);
}

.about-ielts-exam-sec {
  padding-bottom: 30px;
}

.about-ielts-exam-sec .download-guide img {
  max-width: 240px;
}

.eligibility-sec {
  padding: 31px 0 40px;
}

.eligibility-h4 {
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 27px;
}

.must-have-text {
  display: flex;
  align-items: center;
}

.must-have-text img {
  width: 100%;
  max-width: 100px;
}

.must-have-text p {
  font-weight: 400;
  font-size: 18px;
  padding-left: 50px;
}

.general-facts-sec {
  background: rgb(13 54 89 / 5%);
  padding: 40px 0;
}

.general-fact-list h4 {
  font-size: 18px;
  font-weight: 500;
}

.general-fact-list p {
  color: var(--subtext);
  font-size: 16px;
  line-height: 30px;
}

.component-main-sec {
  padding: 50px 0;
}

.listing-components-card {
  text-align: center;
  background: var(--white);
  box-shadow: 0 5px 15px rgb(0 0 0 / 16%);
  border-radius: 20px;
  padding: 21px 21px 30px;
  height: 100%;
}

.listing-components-card h5 {
  font-size: 20px;
  font-weight: 500;
}

.counter-components {
  display: flex;
  padding: 25px 0;
}

.counter-components div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.counter-components h5 {
  font-size: 16px;
  padding-top: 10px;
}

.listing-components-card p {
  font-size: 15px;
  font-weight: 400;
  color: var(--subtext);
}

.free-main-sec {
  padding-bottom: 50px;
}

.free-cards {
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  padding: 30px;
}

.free-cards h3 {
  font-size: 20px;
  font-weight: 500;
}

.free-cards h4 {
  font-size: 17px;
  font-weight: 400;
  color: var(--border-color);
  padding: 20px 0;
}

.registration-process p {
  font-size: 16px;
  color: var(--subtext);
  font-weight: 400;
  line-height: 26px;
}

.registration-process {
  padding-bottom: 120px;
}

.preparation-tips .card-first img {
  border-radius: 20px 20px 0 0;
}

.preparation-tips .client-details p {
  padding: 30px 0;
  max-width: 300px;
  margin: 0 auto;
}

.faq-main-sec {
  padding: 100px 0;
}

.accordion-main-sec {
  padding-top: 80px;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

/* accordion-start */
.accordion-main-sec .accordion-item {
  background: var(--white);
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  border-radius: 10px;
  margin-bottom: 30px;
}

.accordion-main-sec .accordion-button {
  border-radius: 10px;
  background: var(--white);
  color: var(--theme-color);
  font-size: 16px;
  font-weight: 400;
}

.accordion-main-sec .accordion-button:not(.collapsed) {
  color: var(--theme-color);
  background-color: transparent;
  box-shadow: none;
}

.accordion-main-sec .accordion-body {
  font-size: 15px;
  color: var(--subtext);
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 0px;
}

.accordion-header .accordion-item-static:not(.collapsed)::after {
  background-image: none;
}

/* accordion-end */
.reg-card-details {
  background-image: url(../images/Design-2.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: left;
  background-size: contain;
  text-align: center;
  padding: 50px;
}

.registration-cards-process {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.reg-card-details img {
  margin-bottom: 30px;
  width: 100%;
  max-width: 46px;
}

.services-banner {
  width: 100%;
}

.how-to-transglobe-can-sec {
  padding: 100px 0;
}

.how-to-transglobe-can-sec p {
  font-size: 16px;
  color: var(--subtext);
  font-weight: 400;
  padding: 40px 0 50px;
}

.book-a-free-consaltation h3 {
  font-size: 20px;
}

.service-first {
  background: var(--white);
  border-radius: 10px;
  /* box-shadow: 0 0 20px rgb(0 0 0 / 16%); */
  text-align: center;
  padding-right: 20px;
  margin-bottom: 30px;
  /* padding: 15px; */
  height: 100%;
  /* background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%); */
}

.service-first h6 {
  color: var(--subtext);
  font-size: 16px;
  font-weight: 400;
  padding-top: 30px;
  line-height: 25px;
  text-align: center;
}

.service-first img {
  width: 100%;
  max-width: 90px;
}

.take-our-virtual-sec {
  padding-bottom: 60px;
}

.virtual-bg-img {
  position: relative;
}

.virtual-data {
  display: flex;
  position: absolute;
  top: 50%;
  width: 100%;
  justify-content: space-around;
}

.btn-white:hover {
  border-color: var(--white);
  color: var(--white);
  background: transparent;
}

.virtual-data p {
  font-size: 35px;
  color: var(--white);
}

.btn-white {
  background: var(--white);
  border-radius: 10px;
  padding: 10px 15px;
  color: var(--theme-color);
  font-size: 20px;
  font-weight: 500;
}

.virtual-bg-img img {
  width: 100%;
}

.studern-services-card {
  padding-top: 40px;
}

.card-gradiont h4 {
  color: var(--theme-color);
  font-size: 22px;
  font-weight: 500;
}

.card-gradiont p {
  color: var(--subtext);
  font-size: 16px;
  padding-top: 15px;
  line-height: 28px;
}

.student-services-sec {
  padding-bottom: 40px;
}

.our-affiliation-main-sec {
  background: rgb(13 54 89 / 5%);
  padding: 60px 0;
}

.affiliat-img {
  padding-top: 33px;
}

.affiliat-img .row {
  align-items: center;
}

.affiliat-logo {
  text-align: center;
}

.affiliat-logo img {
  width: 100%;
  max-width: 227px;
}

.book-a-free-consaltation {
  background: var(--white);
  border-radius: .3rem;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  padding: 30px;
}

.service-right-img img {
  width: 100%;
  max-width: 370px;
}

.book-a-free-consaltation .row {
  align-items: center;
}

/* .scholarship-padding .overflow .canada-blog-content p{
    overflow-x: hidden;
  } */

/* form */
.book-form .form-label {
  font-size: 16px;
  color: rgb(96 96 96 / 60%);
}

.book-form .form-control {
  color: var(--subtext);
  font-size: 14px;
  border-color: rgb(112 112 112 / 40%);
  padding-top: 8px;
  padding-bottom: 8px;
}

.book-form .form-select {
  color: var(--subtext);
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: unset;
}

.form-select:focus {
  box-shadow: unset;
  border-color: rgb(112 112 112 / 40%);
}

/* form */
.btn-apply {
  background: var(--theme-color);
  color: var(--white);
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 20px;
  margin-top: 20px;
}

.btn-apply:hover {
  color: white;
  background-color: #0d3659;
  border-color: white;
}

/* .contact-us-banner {
    background-image: url(../images/Contact-Us.png);
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  } */
.banner-about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* .scholership-banner{
    background-image: url(../images/about-us.png);
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  } */
.contact-address {
  padding-top: 50px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 10px;
}

.service-first.our-address {
  text-align: left;
  height: 100%;
}

.location-icon {
  display: flex;
  align-items: center;
}

.location-icon h4 {
  border-left: 10px solid red;
}

.location-icon img {
  width: 100%;
  max-width: 63px;
}

.location-icon h5 {
  padding-left: 0px;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-color);
}

.home-address a {
  display: flex;
  align-items: baseline;
}

.home-address {
  display: flex;
  align-items: baseline;
  padding: 0;
  margin: 10px 0 0;
}

.home-addressV2 {
  display: flex;
  align-items: baseline;
  padding: 10px 0 0 30px;
}

.home-address a {
  color: var(--theme-color);
}

.home-addressV2 a {
  color: var(--theme-color);
}

.home-address p {
  font-size: 13px;
  padding-left: 10px;
  font-weight: 400;
  color: var(--theme-color);
}

.home-addressV2 p {
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--theme-color);
}

.home-address i {
  color: var(--subtext);
  font-size: 16px;
  position: relative;
  top: 3px;
}

.our-office-locations {
  padding: 70px 0;
}

.director-flex {
  display: flex;
  align-items: center;
}

.director-content-sec {
  text-align: left;
  padding-right: 20px;
}

.swiper.swiper-trending {
  padding: 0px 20px 60px;
}

.rating-star {
  display: flex !important;
  justify-content: center;
}

.rating-star img {
  width: auto !important;
  height: auto !important;
}

.explore-flag-card .card-first {
  max-width: 349px;
}

.office-details {
  background-image: url(../images/trash/office-details.png);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pune-office-details {
  background-image: url(../images/trash/Office-Details-Page-Banner.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.office-details .banner-img h1 {
  font-size: 64px;
  line-height: 78px;
  max-width: 790px;
}

.office-details .banner-img p {
  font-size: 24px;
  line-height: 44px;
  max-width: 619px;
  padding: 50px 0 42px;
  color: var(--subtext);
}

.vetaran-sec h5 {
  font-size: 31px;
  font-weight: 500;
}

.vetaran-sec h6 {
  font-size: 18px;
  font-weight: 400;
  color: var(--subtext);
  line-height: 28px;
  padding: 50px 0 70px;
}

.why-choose-left-content h2 {
  font-size: 30px;
  padding-bottom: 50px;
}

.why-choose-left-content ul li {
  padding: 0 0 30px;
  display: flex;
  align-items: center;
}

.why-choose-left-content ul li img {
  max-width: 20px;
  width: 100%;
  height: 100%;
  margin-right: 20px;
}

.trans-globe-video iframe {
  border-radius: 20px;
  width: 530px;
  height: 315px;
}

.office-counter {
  /* background-image: url(../images/bg.png); */
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin: 0px 0px 20px 0px;
  /* margin: 50px 0; */
  /* padding: 60px 0; */
}

.office-counter-pune {
  /* background-image: url(../images/pune-office-website.jpg); */
}

.counter-path-bg {
  background-image: url(../images/trash/Couner-Path.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  background-position: center;
  background-size: 100%;
  padding: 71px 23px;
}

.counter-path-bg h4 {
  font-size: 50px;
  font-weight: 500;
  color: white;
}

.counter-path-bg p {
  font-size: 24px;
  line-height: 35px;
  padding: 30px 0;
  color: white;
}

.office-details-services .client-details {
  text-align: left;
}

.office-details-services .client-details p {
  padding-bottom: 0;
  color: var(--subtext);
  line-height: 29px;
  font-size: 16px;
}

.office-details-services .card-first {
  display: block;
  height: 100%;
  min-height: 508px;
}

.office-details-services .client-detail-img {
  max-width: 100%;
}

.banner-blog img {
  width: 100%;
}

.addres-p {
  font-size: 20px;
  padding: 0px 0px 20px 0px;
}

.aboad-tag {
  font-size: 25px;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 500;
  background: rgb(13 54 89 / 20%);
}

.canada-blog-content h2 {
  font-size: 26px;
  padding: 30px 0 10px;
}

.canada-blog-content p {
  font-weight: 400;
  color: var(--subtext);
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 25px;
}

.by-trans-globe {
  display: flex;
  align-items: center;
}

.calendar-div {
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.calendar-div i {
  padding-right: 10px;
  color: var(--subtext);
}

.by-trans-globe h6 {
  font-size: 16px;
  color: var(--subtext);
  font-weight: 400;
}

.blog-main-sec .country-name-details {
  padding: 15px 30px;
}

.latest-blog-title {
  font-size: 23px;
  padding-bottom: 28px;
}

.latest-blog-list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.latest-blog-img img {
  height: auto;
  object-fit: cover;
  width: 150px;
}

.latest-blog-img {
  margin-right: 10px;
}

.latest-blog-list p {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
}

.latest-blog-list a {
  padding-top: 10px;
  display: block;
  color: var(--theme-color);
}

.latest-blog-list a:hover {
  color: var(--text-border);
}

.scholarship-main-sec {
  padding:  0;
}

.blog-details-question {
  padding-bottom: 10px;
}

.canada-blog-content ul {
  margin-left: 30px;
  padding: 0 0 20px 0;
}

.canada-blog-content ul li {
  list-style-type: disc;
  padding-bottom: 10px;
  color: var(--subtext);
  font-size: 16px;
}

.card-desc {
  display: flex;
}

.testimonials-text h1 {
  color: var(--theme-color);
}

.testimonial-banner {
  position: relative;
}

.testimonial-banner img {
  width: 100%;
}

.country-banner-text.testimonials-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10%;
}

.testimonial-detail-img {
  text-align: center;
  margin-bottom: 30px;
}

.testimonial-detail-img .client-img {
  margin-bottom: 0;
}

.testimonial-detail-img .client-details {
  padding: 0 0 0 15px;
  text-align: left;
	overflow: auto;
    max-height: 200px;
}

.testimonial-detail-img .card-first {
  padding: 10px;
  margin-bottom: 10px;
}

.testimonial-cards {
  padding: 100px 0 70px;
}

.testimonial-video-sec {
  background: rgb(13 54 89 / 10%);
  width: 100%;
  padding: 100px 0;
}

.testimonial-video iframe {
  width: 100%;
  height: 355px;
  border-radius: 20px;
}

.scholarship-padding {
  padding-top: 0px;
}

.canada-blog-content a {
  color: var(--theme-color);
  /*     display: flex; */
  align-items: center;
}

.newsletter-base p {
  font-size: 16px;
  padding: 15px 0 29px;
}

.newsletter-base h2 {
  font-size: 25px;
}

.subscribe-input-field {
  display: flex;
  align-items: center;
}

.subscribe-newsletter-sec {
  padding: 0 0 100px 0;
}

.subscribe-input-field input {
  padding: 10px 12px;
  width: 100%;
  display: block;
  min-width: 500px;
}

.btn.btn-subscribe {
  background: var(--theme-color);
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  margin-left: 40px;
}

.btn.btn-subscribe:hover {
  background: transparent;
  color: var(--theme-color);
  border-color: var(--theme-color);
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
}

.progress {
  background-color: transparent;
  height: 40px;
}

.progress-line {
  background: url(../images/down-arrow.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
  text-align: center;
}

.progress-line img {
  padding-bottom: 5px;
}

#scroll {
  position: absolute;
  top: 0;
  height: 0px;
  width: 10px;
  left: 50%;
  transition: width 0.1s ease;
  z-index: 9;
  background-color: var(--theme-color);
}

/* footer-start */
.footer-first-menu {
  padding: 35px 0;
  /* border-top: 1px solid var(--border-color); */
}

.office-location {
  padding: 35px 0;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.first-menu-list h5 {
  font-size: 16px;
  line-height: 23px;
  padding-bottom: 17px;
  font-weight: 500;
  color: var(--theme-color);
}

.first-menu-list h5 a {
  color: var(--theme-color);
}

.first-menu-list ul li a {
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: var(--theme-color);
}

.copy-right {
  padding: 30px 0;
}

.copy-right h5 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border-top: 1px solid var(--theme-color);
  padding-top: 27px;
}

.copy-right h5 span {
  font-weight: 500;
}

.ofc-location-title {
  font-size: 20px;
  padding-bottom: 32px;
}

/* footer-end */

/* services-page-swiper-slider-s */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
}

.affiliat-img .swiper-wrapper {
  align-items: center;
}

/* services-page-swiper-slider-e */

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--theme-color) !important;
}

.swiper-pagination-bullet {
  background: transparent !important;
  border: 1px solid var(--theme-color);
  width: 11px !important;
  height: 11px !important;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-next {
  background-image: url(../images/rightSideArrow.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(../images/LeftSideArrow.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0 !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: calc(var(--swiper-navigation-size) / 44 * 44) !important;
}

/* index-swiper-slider-s */
.client-say .our-clients-say .client-details {
  min-height: 455px;
}

.clients-card .swiper.swiper-clients-say {
  padding: 60px 19px;
}

.client-say .clients-card .swiper-slide img {
  margin: 0 auto;
}

.university-partners .swiper.swiper-university-partners {
  padding: 40px 0 70px;
}

.scholarship-card .swiper-container {
  padding: 50px 0;
}

.directore-inspired-details .swiper.swiper-directors {
  padding-bottom: 50px;
}

/* index-swiper-slider-e */

/* country-details-s */
.swiper.swiper-top-universitys {
  padding: 0 20px 60px;
}

.swiper.swiper-visa-options {
  padding: 0 20px 60px;
}

.swiper.swiper-more-destinations {
  padding: 0 20px 60px;
}

.post-admission .swiper-slide {
  height: auto;
}

/* .swiper-top-universitys .swiper-pagination{display: none;} */
/* country-details-e */

/* ielts-swiper-slider-s */
.clients-card .swiper.swiper-preparation {
  padding: 60px 19px;
}

.swiper-top-universitys .client-detail-img {
  max-width: 353px;
}

.swiper-top-universitys .client-detail-img .top-uni-logo img {
  max-width: 160px;
  min-height: 166px;
}

.top-uni-logo.university-nbc img {
  width: 100%;
  height: 100%;
  max-width: 200px !important;
  min-height: 142px !important;
}

.client-detail-img {
  max-width: 363px;
  width: 100%;
}

.listing-components-card {
  /* max-width: 350px; */
  max-width: 364px;
}

.listing-components .swiper.swiper-components {
  padding: 30px 19px;
}

.listing-components .swiper-slide {
  height: auto;
}

.swiper-slide .swiper-slide-active {
  transform: scale(1);
}

/* ielts-swiper-slider-e */

.scholarship-card .swiper-container {
  position: relative;
}

.scholarship-card .swiper-container>.swiper-slide__content {
  position: absolute;
  top: 0;
}

.scholarship-card .swiper-slide {
  transition: all 200ms linear;
  transform: scale(0.8);
}

.scholarship-card .swiper-slide.swiper-slide-active {
  transform: scale(1);
}

.scholarship-card .swiper-slide.swiper-slide-active .scholarship-details {
  box-shadow: 0 0 15px rgb(0 0 0 / 16%);
}

.scholarship-card .swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.scholarship-card .swiper-pointer-events {
  touch-action: pan-y;
  padding: 30px 0 0;
}

.counter-dataimg {
  background-image: url(../images/trash/Couner-Path.png);
  width: 100%;
  height: 100%;
  max-width: 75px;
  min-height: 75px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 2-aug-s */
.benefits-main-sec {
  padding: 0;
}

.benefits-main-sec .client-details h4 {
  font-size: 18px;
  line-height: 27px;
}

.benefits-main-sec .client-details p {
  font-size: 14px;
}

.benefits-main-sec p {
  padding-bottom: 10px;
}

.benefits-main-sec .card-first {
  margin: 10px 0;
  height: 100%;
  min-height: 290px;
}

.employment-opportunitys {
  padding: 150px 0 0;
}

.benefits-main-sec.benefits-of-dubai .card-first {
  min-height: 292px;
}

.benefits-main-sec.benefits-of-uk .card-first {
  min-height: 252px;
}

.benefits-main-sec.benefits-of-usa .card-first {
  min-height: 260px;
}

/* 2-aug-e */

/* 3-aug-s */
.we-are-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.who-we-are-sec {
  padding: 50px 0 20px;
}

.who-we-are-top {
  padding-top: 30px;
}

.who-we-are-details p {
  font-size: 16px;
  /* padding-bottom: 32px; */
  color: var(--subtext);
}

.why-choose-left-content.being-member h2 {
  font-size: 25px;
  font-weight: 400;
  padding-bottom: 32px;
}

.why-choose-left-content.being-member ul {
  padding-left: 20px;
}

.why-choose-left-content.being-member ul li {
  font-size: 15px;
  color: var(--subtext);
}

.why-choose-left-content.being-member ul li img {
  max-width: 17px;
  margin-right: 16px;
}

.why-choose-left-content.being-member p {
  font-size: 16px;
  color: var(--subtext);
}

.all-team-member.about-page-lead-section {
  padding: 80px 0;
}

.all-team-member {
  padding: 30px 0;
  background-color: rgb(13 54 89 / 3%);
}

.team-introduction p {
  font-size: 15px;
  padding-bottom: 11px;
  line-height: 22px;
  color: var(--subtext);
}

.team-introduction a {
  color: var(--red-font-color);
  border-bottom: 1px solid var(--red-font-color);
  font-size: 16px;
  font-weight: 400;
}

.all-team-member .row {
  align-items: center;
}

.gradiont-top-img img {
  width: 100%;
  height: 100%;
}

.card-gradiont {
  padding: 30px 20px 36px;
}

.gradiont-bg-color {
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  border-radius: 30px;
  height: 100%;
}

.card-gradiont a {
  font-size: 18px;
  color: var(--red-font-color);
  border-bottom: 1px solid var(--red-font-color);
  display: inline-block;
  padding-top: 20px;
}

.top-uni-logo img {
  width: auto;
  margin: 20px 0;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  max-width: 160px;
  min-height: 200px;
}

.for-all-your-study {
  padding-bottom: 40px;
}

.university-list-sec .accordion-main-sec {
  padding-top: 50px;
}

.contact-modal .row {
  align-items: center;
}

.contact-modal .book-form .form-label {
  font-size: 15px;
}

.contact-modal .form-control {
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.contact-modal .book-form .form-select {
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.contact-modal .book-form .form-select::placeholder {
  font-size: 14px;
}

.contact-modal .form-control::placeholder {
  font-size: 14px;
}

.contact-modal .btn-apply {
  font-size: 16px;
}

/* .contact-modal .btn-apply:hover {
    color: #0d3659;
    background-color: transparent;
    border-color: #0d3659;
  } */

.contact-modal .service-right-img {
  text-align: center;
}

.contact-modal .service-right-img img {
  max-width: 310px;
}

.uk-logos-accordions {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #ddd;
  min-height: 200px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uk-logos-accordions img {
  width: 100%;
  height: 100%;
  max-width: 180px;
  object-fit: cover;
  object-position: center;
  padding: 10px;
  margin-bottom: 10px;
}

.university-list-sec .accordion-body .row {
  align-items: center;
  margin: 0 auto;
}

.university-list-sec .accordion-main-sec .accordion-button {
  font-size: 19px;
}

.header-open-menu .accordion-item {
  /* background-color: #224360; */
  /* background-color: transparent; */
  border: none;
  color: #fff;
}

.header-open-menu .accordion-button {
  /* background-color: #224360; */
  background-color: transparent;
  color: #224360;
  font-weight: 400;
  padding: 14px 8px 3px;
  display: flex;
  align-items: center;
}

.header-open-menu .accordion-header {
  background-color: transparent;
}

.accordion-header .accordion-item-static {
  background-color: #224360;
  color: #fff;
}

.header-open-menu .accordion-body h5 {
  font-weight: 300;
  font-size: 16px;
  text-align: left;
  padding-bottom: 12px;
}

.header-open-menu .accordion-body {
  background: #27557c;
}

.header-open-menu .open-menu {
  display: block;
}

.header-open-menu .accordion-button.remove-after::after {
  content: none;
}

.header-open-menu .accordion-collapse.collapse h5 a {
  color: #fff;
}

.header-open-menu .accordion-collapse.collapsing a {
  color: #fff;
}

/* .header-open-menu .accordion-button::after {
    background-image: url(../images/dow-arrow.svg);
  }

  .header-open-menu .accordion-button:not(.collapsed)::after {
    background-image: url(../images/dow-arrow-blue.svg);
  } */
.header-open-menu .accordion-button::after {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  background-size: 15px;
}

.header-open-menu .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #224360;
}

.services-main-section {
  padding-top: 150px;
}

.transglob-service-card.services-cards {
  padding: 50px 0 50px;
}

.services-main-section .services-title p {
  padding-top: 10px;
}

.how-to-Trans.Globe-can-sec.services-contact {
  padding: 100px 0 70px;
}

.how-to-Trans.Globe-can-sec.services-contact p {
  padding-bottom: 20px;
}

.benefits-main-sec p {
  padding: 18px 20px;
}

.how-to-Trans.Globe-can-sec {
  padding: 80px 0 50px;
}

section.how-to-Trans.Globe-can-sec p {
  padding-top: 14px;
}

.who-we-are-sec.about-who-we-are {
  padding: 0px 0 60px;
}

.about-who-we-are .who-we-are-top {
  padding-top: 30px;
}

.about-who-we-are .who-we-are-details p {
  padding-bottom: 12px;
}

.about-list-sec {
  padding-left: 20px;
}

.about-list-sec li {
  padding-bottom: 10px;
  color: var(--subtext);
  font-size: 15px;
}

.who-we-are-sec.scholership-bur {
  padding: 80px 0 0px;
}

.who-we-are-sec.scholership-bur .who-we-are-top {
  padding-top: 20px;
}

.who-we-are-sec.uk-scholartship .who-we-are-top .who-we-are-details p {
  padding-bottom: 18px;
}

.who-we-are-sec.uk-scholartship .who-we-are-top {
  padding-top: 20px;
}

.who-we-are-sec.uk-scholartship,
.who-we-are-sec.australia-scholartship,
.who-we-are-sec.usa-scholership {
  padding: 50px 0 0px;
}

.who-we-are-sec.australia-scholership .who-we-are-top .who-we-are-details p {
  padding-bottom: 18px;
}

.who-we-are-sec.australia-scholership .who-we-are-top {
  padding-top: 20px;
}

.ed-title h5 {
  font-size: 17px;
}

.ed-title p {
  font-size: 15px;
}

/* careeer */
.career-form {
  margin: 30px 0 0 0px;
}

.career-form a {
  font-size: 17px;
  line-height: 20px;
  color: var(--theme-color);
  text-transform: lowercase;
  margin-right: 20px;
}

.career-form a i {
  font-size: 20px;
}

.service-right-img {
  text-align: center;
}

.team-menber-parent .team-member-name {
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  padding: 10px 24px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-menber-parent {
  padding: 50px 0 0 0;
}

.team-member-name h5 {
  font-size: 18px;
  line-height: 23px;
}

.join-our-team-main {
  padding: 60px 0;
}

.join-our-team-main .row {
  padding: 20px 0 0;
}

.join-our-parent {
  padding: 20px;
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
  border-radius: 10px;
  margin: 20px 0 0;
}

.join-our-parent h4 {
  color: var(--theme-color);
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 10px;
}

.join-our-parent ul li {
  font-size: 15px;
  line-height: 20px;
  color: var(--theme-color);
  padding-bottom: 10px;
}

.career-details {
  padding: 70px 0;
}

.for-career-main h2 {
  font-size: 20px;
  font-weight: 500;
}

.process-img {
  padding: 30px 0 0;
}

.process-img img {
  width: 100%;
}

.scholarship-card {
  padding: 50px 0 0;
}

.services-banner img {
  width: 100%;
}

.intakes-main-sec {
  padding: 50px 0 0;
}

.intakes-main-sec .intake-card {
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
  border-radius: 10px;
  padding: 13px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.intakes-main-sec .intake-card p {
  font-size: 15px;
  font-weight: 400;
  color: var(--subtext);
  line-height: 22px;
  text-align: left;
}

.intakes-main-sec .row {
  padding: 30px 0 0;
}

.canada-process img {
  width: 100%;
  margin-bottom: 30px;
}

.australia-process img {
  width: 100%;
  margin: 30px 0 50px;
}

.call-us-btn {
  margin-right: 10px;
}

.btn.btn-comman .fa-solid.fa-calendar {
  margin-right: 5px;
}

.scholarships-cards.uk-scholarship-card {
  justify-content: flex-start;
}

.scholarships-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.scholarships-cards .intake-card {
  margin: 0 10px;
}

.scholarships-cards .intake-card {
  margin: 0 10px 0 0;
  height: 100%;
  padding: 10px;
}

.scholarships-cards .intake-card p {
  font-size: 13px;
}

.site-red-font {
  color: var(--red-font-color);
}

.accordion-header {
  margin-bottom: 0;
  background-color: #f9f9f9;
}

.bottom-menu .accordion-button {
  display: flex;
  align-items: end;
}

.bottom-menu .navbar-light .navbar-nav .nav-link {
  color: var(--theme-color);
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #f9f9f9;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border-radius: 0px !important;
}

.test-list-image {
  width: 100%;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-list-image img {
  width: 100%;
  height: 100%;
  max-width: 350px;
  object-fit: cover;
  object-position: center;
  padding: 10px;
  margin-bottom: 10px;
}

.test-list-title {
  margin-bottom: 5px;
  line-height: 1.5 !important;
  height: 60px;
}

.text-justify {
  text-align: justify;
}

.about-page-lead-section {
  background-image: url(../images/trash/our-virtual.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-page-lead-section h2 {
  color: #fff;
  text-align: center;
  font-size: 4rem;
}

/* 30-jan-s */
.post-of-study-visa-parent {
  padding: 20px 0;
}

.NPSV-stream h2 {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 12px;
}

.NPSV-stream p {
  font-size: 15px;
  line-height: 20px;
}

.Eligibility-Parent {
  padding-top: 15px;
}

.Eligibility-Parent h3 {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 20px;
}

.Eligibility-Parent ul li {
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 13px;
}

.Eligibility-Parent p {
  font-size: 16px;
  line-height: 20px;
}

.post-of-study-visa-parent .table-responsive {
  padding-top: 20px;
}

.post-of-study-visa-parent .table.table-bordered {
  background-color: var(--theme-color);
  color: #fff;
}

.post-of-study-visa-parent .table.table-bordered tbody,
td,
tfoot,
th,
thead,
tr {
  text-align: left;
}

.canada-after-study li {
  color: var(--subtext);
  font-size: 14px;
}

.canada-after-study p {
  padding-bottom: 5px;
}

footer.footer-sec {
  /* background-color: #0d3659; */
  background: radial-gradient(circle at 0% 90%, rgba(22, 232, 54, 0.2) 0%, rgba(170, 201, 109, 0.2) 100%);
}

/* 9-FEB-s */
.component-main-sec .listing-components .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

/* 9-FEB-e */

.navmenu-btn {
  display: none;
}

.footer-btn {
  color: #fff !important;
}

.footer-btn:hover {
  color: var(--theme-color) !important;
}




/* 30-jan-e */

/* media-query */
@media only screen and (max-width: 1380px) {
  .client-detail-img {
    max-width: 468px;
  }
}

@media only screen and (max-width: 1200px) {
  .client-details {
    height: 100%;
    min-height: 553px;
  }

  .card-first.text-center.country-card-list .client-details {
    min-height: auto;
  }

  .trending-sec .client-details {
    min-height: auto;
  }

  .top-universitys .client-details {
    min-height: auto;
  }

  .post-admission-details {
    height: 100%;
  }

  .home-address {
    padding: 50px 0 0 21px;
  }

  .director-content-sec {
    max-width: 700px;
  }

  .university-logos img {
    max-width: 119px;
    min-height: 110px;
  }

  .blog-main-sec .card-first.text-center.country-card-list .client-details {
    /* min-height: 520px; */
    min-height: 320px;
  }

  .testimonial-detail-img .client-details {
    min-height: auto;
  }

  .directore-img img {
    width: 100%;
  }

  .admission-tab-menu li {
    margin-right: 50px;
  }

  .newsletter-base p {
    padding: 50px 0 40px;
  }

  .admission-tab-menu li {
    margin-right: 50px;
  }

  .for-all-your-study {
    padding-bottom: 30px;
  }

  .university-partners {
    padding: 60px 0;
  }

  .bottom-menu .navbar-expand-lg .navbar-nav {
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .directore-img img {
    max-width: 100%;
  }

  .university-logos img {
    max-width: 100px;
  }

  .card-first.text-center.country-card-list .client-details {
    min-height: 313px;
    height: 100%;
  }

  .country-list-card .scholarship-details {
    padding: 16px 21px;
  }

  .directore-desk.why-study-canada {
    padding: 70px 0;
  }

  .scholarship-sec.country-list-card {
    padding: 70px 0 20px;
  }

  .benefits-main-sec .client-details p {
    font-size: 13px;
    line-height: 19px;
  }

  .admission-timeline {
    padding-top: 20px;
  }

  .top-uni-logo img {
    width: 100%;
  }

  .rating-user-history {
    padding: 38px 0 20px 0px;
  }

  .program-course-icon img {
    max-width: 42px;
  }

  .popular-program-sec .opportunity-text p {
    font-size: 14px;
  }

  .university-card-bg img {
    max-width: 124px;
  }

  .university-card-bg {
    min-height: 134px;
  }

  .websites-link-card {
    height: 84%;
  }

  .about-the-test {
    padding-top: 30px;
  }

  .about-the-test p {
    padding: 15px 0 20px;
  }

  .counter-components div {
    margin: 10px 0;
  }

  .counter-dataimg img {
    max-width: 58px;
  }

  .counter-components {
    padding: 10px 0;
  }

  .service-first.our-address {
    padding: 20px;
  }

  .location-icon h5 {
    font-size: 20px;
    padding-left: 15px;
  }

  .location-icon img {
    max-width: 61px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

  .scholarship-card .swiper-container {
    padding: 50px 0;
  }

  .scholarship-details img {
    max-width: 90px;
    margin-right: 10px;
  }

  .scholarship-details h3 {
    font-size: 22px;
  }

  .scholarship-sec {
    padding: 80px 0;
  }

  .flag-img img {
    min-height: auto;
  }

  .office-details .banner-img h1 {
    font-size: 54px;
    line-height: 73px;
  }

  .office-details .banner-img p {
    font-size: 22px;
    line-height: 39px;
    max-width: 470px;
  }

  .how-to-transglobe-can-sec {
    padding: 100px 0;
  }

  .how-to-transglobe-can-sec p {
    padding: 45px 0 40px;
  }

  .vetaran-sec h5 {
    font-size: 27px;
  }

  .vetaran-sec h6 {
    padding: 30px 0 70px;
    font-size: 17px;
  }

  .why-choose-left-content h2 {
    padding-bottom: 30px;
    font-size: 28px;
  }

  .trans-globe-video iframe {
    width: 470px;
  }

  .office-counter {
    margin: 20px 0;
    /* margin: 60px 0; */
    /* padding: 52px 0; */
  }

  .counter-path-bg {
    padding: 33px 23px;
  }

  .counter-path-bg h4 {
    font-size: 45px;
    color: white;

  }

  .counter-path-bg p {
    font-size: 19px;
    line-height: 26px;
  }

  .event-main-sec .card-first.text-center.country-card-list .client-details {
    min-height: 490px;
  }

  .all-country-cards.blog-main-sec.event-main-sec {
    padding: 30px 0;
  }

  .all-country-cards.blog-main-sec {
    padding: 80px 0;
  }

  /* .blog-main-sec .card-first.text-center.country-card-list .client-details {
      min-height: 484px;
    } */

  .latest-blog-list p {
    font-size: 14px;
    line-height: 23px;
  }

  .canada-blog-content h2 {
    padding: 32px 0 20px;
  }

  .testimonial-video iframe {
    height: 255px;
  }

  .blur-bg {
    margin-top: -73px;
  }

  .newsletter-base h2 {
    font-size: 26px;
  }

  .who-we-are-details p {
    padding-bottom: 20px;
  }

  .why-choose-left-content ul li {
    padding: 0 0 24px;
  }

  .benefits-define .client-details {
    min-height: 350px;
  }

  .admission-tab-menu li {
    margin-right: 30px;
  }

  .admission-tabs {
    padding-top: 20px;
  }

  .benefits-main-sec.benefits-of-uk .card-first {
    min-height: auto;
  }

  .benefits-main-sec.benefits-of-uk .client-details {
    min-height: 310px;
  }

  .newsletter-base p {
    padding: 30px 0 40px;
  }

  .scholarship-main-sec {
    padding: 120px 0 80px;
  }

  .newsletter-base h2 {
    font-size: 28px;
  }

  .canada-card img {
    width: 100%;
  }

  .singapore-benefits .benefits-define .client-details {
    min-height: 244px;
  }

  .how-to-apply-sec h5 {
    line-height: 30px;
  }

  .service-first {
    padding: 40px 20px;
  }

  .ielts-main-sec {
    padding: 50px 0 70px;
  }

  .component-main-sec .eligibility-h4 {
    padding-bottom: 0;
  }

  .free-cards {
    padding: 25px;
  }

  .free-cards h4 {
    padding: 16px 0 0;
  }

  .office-details-services .card-first {
    min-height: 478px;
  }

  .new-zealand-benefits .benefits-main-sec .card-first {
    min-height: auto;
  }

  .new-zealand-benefits .benefits-main-sec .card-first .client-details {
    min-height: 286px;
  }

  .banner-btn h2 {
    font-size: 30px;
  }

  .title-sec h2 {
    font-size: 29px;
  }

  .canada-card {
    padding-right: 35px;
  }

  .scholarship-sec.country-list-card .scholarship-details img {
    width: auto;
  }

  .benefits-main-sec p {
    padding: 9px 20px;
  }

  .directore-inspired-details p {
    padding-bottom: 10px !important;
    font-size: 15px;
  }

  .all-need-img img {
    max-width: 320px;
  }

  .all-need-data h3 {
    font-size: 25px;
  }

  .all-need-data p {
    font-size: 16px;
    padding: 23px 0;
    line-height: 24px;
  }

  .all-need-data a {
    font-size: 15px;
  }

  .directore-inspired-details p {
    padding-bottom: 15px;
  }

  .all-country-cards {
    padding: 50px 0;
  }

  /*
    .apply-margin-bottom {
      margin-bottom: 40px;
    } */

  .header-open-inner-sec {
    width: 35%;
  }

  .services-main-section {
    padding-top: 80px;
  }

  .about-who-we-are .who-we-are-top {
    padding-top: 30px;
  }

  .about-who-we-are .who-we-are-top p {
    padding-bottom: 16px;
    font-size: 15px;
  }

  .about-who-we-are .team-introduction p {
    font-size: 15px;
    padding-bottom: 13px;
    line-height: 25px;
  }

  .book-a-free-consaltation h3 {
    font-size: 18px;
  }

  .book-form {
    padding-top: 20px;
  }

  .book-form .form-label {
    font-size: 17px;
  }

}

@media only screen and (max-width: 991px) {
  .desktop-icons {
    display: none;
  }

  .mobile-icons {
    display: block;
  }

  .location-icon h5 {
    font-size: 18px;
  }

  .who-we-are-sec.scholership-bur .who-we-are-top .who-we-are-details {
    padding-top: 0;
  }

  .who-we-are-sec.uk-scholartship .who-we-are-top .who-we-are-details {
    padding-top: 0;
  }

  .who-we-are-sec.australia-scholership .who-we-are-top .who-we-are-details {
    padding-top: 0;
  }

  .who-we-are-sec.scholership-bur {
    padding: 60px 0 0px;
  }

  .btn-comman {
    font-size: 15px;
    padding: 4px 11px;
  }

  .banner-img h1 {
    font-size: 40px;
    line-height: 52px;
  }

  .banner-img p {
    max-width: 440px;
    font-size: 15px;
    line-height: 25px;
    padding-top: 25px;
  }

  .site-red-font {
    padding-bottom: 20px;
  }

  .banner-btn a {
    font-size: 14px;
    padding: 7px 18px;
  }

  .title-sec h2 {
    font-size: 28px;
    padding-left: 10px;
    border-left: 8px solid var(--text-border);
  }

  .title-sec a {
    font-size: 16px;
  }

  .client-details {
    min-height: 423px;
  }

  .all-need-data h3 {
    font-size: 20px;
  }

  .all-need-data p {
    padding: 14px 0;
    font-size: 15px;
    line-height: 25px;
  }

  .all-need-data a {
    font-size: 14px;
    padding: 6px 13px;
  }

  .client-img img {
    max-width: 110px;
  }

  .client-details p {
    font-size: 14px;
    line-height: 20px;
  }

  .all-need-img img {
    max-width: 220px;
  }

  .scholarship-card p {
    font-size: 19px;
    padding: 30px 0 60px;
  }

  .scholarship-details {
    padding: 19px 12px;
  }

  .scholarship-details img {
    max-width: 76px;
    margin-right: 24px;
  }

  .scholarship-details h3 {
    font-size: 17px;
    line-height: 20px;
  }

  .scholarship-details h5 {
    font-size: 14px;
  }

  .directore-inspired-details h4 {
    font-size: 19px;
  }

  .blur-bg h5 {
    font-size: 13px;
  }

  .scholarship-sec {
    padding: 100px 0;
  }

  .directore-inspired-details {
    padding-top: 20px;
  }

  .directore-inspired-details p {
    font-size: 14px;
    padding-top: 20px;
  }

  .first-menu-list h5 {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 4px;
  }

  .first-menu-list ul li a {
    font-size: 13px;
    line-height: 21px;
  }

  .ofc-location-title {
    padding-bottom: 15px;
  }

  .office-location {
    padding: 30px 0;
  }

  .copy-right {
    padding: 20px 0;
  }

  .copy-right h5 {
    font-size: 14px;
  }

  .footer-first-menu {
    padding: 22px 0;
  }

  .university-partners {
    padding: 40px 0;
  }

  .all-need-data span {
    font-size: 15px;
  }

  .study-in-canada-main {
    align-items: center;
  }

  .canada-card {
    padding-right: 55px;
  }

  .canada-card img {
    max-width: 280px;
  }

  .banner-btn {
    padding-top: 0;
  }

  a.btn.download-btn {
    font-size: 15px;
    padding: 7px 15px;
  }

  .border-in-right {
    border-right: unset;
  }

  .how-to-apply-sec.border-end-0 {
    padding-left: 0;
    padding-top: 20px;
  }

  .admision-title {
    font-size: 20px;
    padding: 10px 0;
  }

  .post-admission-details {
    padding: 24px;
  }

  .post-admission-details h3 {
    font-size: 18px;
  }

  .visa-option h3 {
    font-size: 24px;
  }

  .admission-tab-menu ul li a {
    font-size: 24px;
  }

  .admission-tab-menu ul li {
    margin-right: 50px;
  }

  .ielts-main-sec p {
    font-size: 22px;
  }

  .websites-link-card h4 {
    font-size: 15px;
  }

  .about-the-test {
    padding-top: 50px;
  }

  .responsive-card {
    margin-bottom: 30px;
  }

  .faq-main-sec {
    padding: 70px 0;
  }

  .accordion-main-sec {
    padding-top: 40px;
  }

  .free-main-sec {
    padding-bottom: 50px;
  }

  .component-main-sec {
    padding: 60px 0;
  }

  .registration-cards-process {
    display: block;
  }

  .reg-card-details {
    padding: 21px;
  }

  .how-to-transglobe-can-sec {
    padding: 70px 0;
  }

  .how-to-transglobe-can-sec p {
    padding: 40px 0 50px;
  }

  .service-first {
    padding: 20px;
  }

  .service-first h6 {
    font-size: 14px;
    line-height: 21px;
    padding-top: 20px;
  }

  .take-our-virtual-sec {
    padding-bottom: 100px;
  }

  .card-gradiont {
    padding: 20px 20px;
  }

  .card-gradiont p {
    padding-top: 8px;
    font-size: 15px;
    line-height: 21px;
  }

  .card-gradiont a {
    padding-top: 9;
    font-size: 16px;
  }

  .student-services-sec {
    padding-bottom: 50px;
  }

  .our-affiliation-main-sec {
    padding: 60px 0;
  }

  .affiliat-logo img {
    max-width: 122px;
  }

  .book-form .form-control {
    padding: 5px 13px;
    font-size: 13px;
  }

  .book-form .form-label {
    font-size: 14px;
  }

  .book-form .form-select {
    padding: 5px 13px;
    font-size: 13px;
  }

  .btn-apply {
    font-size: 15px;
    padding: 6px 18px;
    margin-top: 10px;
  }

  .virtual-data p {
    font-size: 23px;
  }

  .btn-white {
    font-size: 13px;
    padding: 7px 15px;
  }

  .home-address {
    padding: 41px 0 0 21px;
  }

  .counter-components div {
    margin: 10px;
  }

  .counter-components {
    flex-direction: row;
  }

  .director-content-sec {
    max-width: 430px;
  }

  .blur-bg {
    margin-top: -75px;
  }

  .admission-tab-menu li {
    margin-right: 21px;
  }

  .admission-tab-menu li .nav-link {
    font-size: 20px;
  }

  .client-details a {
    font-size: 13px;
  }

  .office-details .banner-img h1 {
    font-size: 44px;
    line-height: 63px;
    max-width: 520px;
  }

  .office-details .banner-img p {
    font-size: 18px;
    line-height: 32px;
    max-width: 394px;
    padding: 30px 0 12px;
  }

  .vetaran-sec h5 {
    font-size: 24px;
  }

  .why-choose-left-content ul li {
    padding: 0 0 13px;
  }

  .vetaran-sec h6 {
    padding: 30px 0 50px;
  }

  .trans-globe-video iframe {
    width: 100%;
    margin-top: 30px;
  }

  .counter-path-bg p {
    font-size: 13px;
    line-height: 17px;
    padding: 10px 0;
  }

  .counter-path-bg h4 {
    font-size: 25px;
    color: white;
  }

  .counter-path-bg {
    padding: 27px 23px;
  }

  .office-details-services .client-details h4 {
    font-size: 22px;
  }

  .office-details-services .client-details p {
    padding: 17px 0 0;
    line-height: 26px;
  }

  .event-main-sec .card-first.text-center.country-card-list .client-details {
    min-height: 500px;
  }

  .event-main-sec .apply-margin-bottom {
    margin-bottom: 40px;
  }

  /* .blog-main-sec .card-first.text-center.country-card-list .client-details {
      min-height: 500px;
    } */

  .blog-main-sec .apply-margin-bottom {
    margin-bottom: 50px;
  }

  .scholarship-main-sec {
    padding: 50px 0;
  }

  .latest-blog-title {
    padding-bottom: 19px;
  }

  .by-trans-globe {
    padding-bottom: 40px;
  }

  .office-details-services .card-first {
    min-height: 508px;
  }

  .testimonial-detail-img .card-first {
    padding: 19px;
  }

  .testimonial-video {
    margin: 20px 0;
  }

  .testimonial-video iframe {
    height: 375px;
  }

  .canada-blog-content a {
    margin-bottom: 30px;
  }

  .top-header {
    height: 50px;
  }

  .who-we-are-sec {
    padding: 80px 0 120px;
  }

  .who-we-are-top .row {
    flex-direction: column-reverse;
  }

  .who-we-are-details {
    padding-top: 30px;
  }

  .team-introduction p {
    font-size: 14px;
    padding-bottom: 15px;
    line-height: 23px;
  }

  .blur-bg {
    max-width: 272px;
    margin-top: -63px;
  }

  .benefits-define .client-details {
    min-height: 310px;
  }

  .opportunities .directore-inspired-details p {
    padding-top: 0;
    padding-bottom: 10px !important;
  }

  .benefits-main-sec.benefits-of-uk .client-details {
    min-height: 290px;
  }

  .singapore-benefits .benefits-define .client-details {
    min-height: 250px;
  }

  .trending-sec .client-details {
    padding: 26px 25px 10px;
  }

  .websites-link-card {
    height: 76%;
  }

  .eligibility-sec {
    padding: 60px 0 60px;
  }

  .eligibility-h4 {
    padding-bottom: 20px;
  }

  .counter-dataimg {
    max-width: 61px;
    min-height: 61px;
  }

  .counter-dataimg h6 {
    font-size: 12px;
  }

  .new-zealand-benefits .benefits-main-sec .card-first .client-details {
    min-height: 244px;
  }

  .repsonsive-directore-desk .directore-inspired-details p {
    padding-top: 0;
    padding-bottom: 11px !important;
  }

  .university-logos img {
    min-height: 100px;
  }

  .banner-img h1 {
    font-size: 43px;
    line-height: 54px;
  }

  .contact-modal .modal-title {
    font-size: 17px;
  }

  .contact-modal .book-form .form-label {
    font-size: 14px;
  }

  .contact-modal .btn-apply {
    font-size: 13px;
  }

  .mobile-view-margin {
    margin-bottom: 25px;
  }

  .rating-user-history {
    padding: 38px 0 0 25px;
  }

  .team-menber-parent .team-member-name {
    margin-bottom: 10px;
    height: auto;
  }

  .team-menber-parent {
    padding: 30px 0 0 0;
  }

  .join-our-team-main .row {
    padding: 10px 0 0;
  }

  /* .bottom-menu .navbar-toggler{
      position: absolute;
      right: 15%;
      top: -55px;
      border: none;
    } */
  .navmenu-btn {
    display: block;
  }

  .bottom-menu .navbar {
    padding: 0;
  }

  .bottom-menu {
    padding: 0 !important;
  }

  .navbar-toggler {
    box-shadow: none;
    border: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
    border: none;
  }

  .bottom-menu .navbar {
    background-color: #fff;
    position: absolute;
    width: 100%;
    box-shadow: 0 3px 6px rgb(0 0 0 / 11%);
  }

  .sc-media-icons ul {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .scholership-banner .country-banner-text h1 {
    font-size: 60px;
  }

  .admission-tab-menu ul li a {
    font-size: 23px;
  }

  .official-websites-links {
    padding: 30px 0 50px;
  }

  .websites-link-card h3 {
    font-size: 18px;
  }

  .websites-link-card h4 {
    padding-top: 7px;
  }

  .about-the-test {
    padding-top: 28px;
  }

  .about-the-test h3 {
    font-size: 20px;
  }

  .about-the-test p {
    padding: 4px 0 10px;
    font-size: 14px;
  }

  .about-ielts-exam-sec {
    padding-bottom: 60px;
  }

  .how-to-Trans.Globe-can-sec {
    padding: 60px 0 20px;
  }

  .take-our-virtual-sec {
    padding-bottom: 70px;
  }

  .studern-services-card {
    padding-top: 40px;
  }

  .eligibility-sec {
    padding: 40px 0 60px;
  }

  .general-fact-list h4 {
    font-size: 17px;
  }

  .general-fact-list p {
    font-size: 15px;
    line-height: 27px;
  }

  .listing-components-card h5 {
    font-size: 18px;
  }

  .counter-dataimg h5 {
    font-size: 15px !important;
  }

  .listing-components-card p {
    font-size: 15px;
  }

  .our-office-locations {
    padding: 50px 0;
  }

  .contact-address {
    padding-top: 30px;
  }

  .mobile-view-margin {
    margin-bottom: 20px;
  }

  .office-details {
    height: 80vh;
  }

  .canada-blog-content h2 {
    padding: 26px 0 30px;
  }

  .office-details-services .card-first {
    min-height: auto;
  }

  .card-gradiont h4 {
    font-size: 20px;
  }

  .card-gradiont p {
    padding-top: 11px;
  }

  .card-gradiont a {
    font-size: 15px;
    padding-top: 11px;
  }

  .benefits-main-sec {
    padding: 30px 0 0px;
  }

  .benefits-main-sec p {
    padding-bottom: 20px;
  }

  .benefits-main-sec.benefits-of-uk .client-details {
    min-height: 270px;
    padding: 35px 15px 0;
  }

  .trending-sec .client-details {
    min-height: 230px;
  }

  .how-to-transglobe-can-sec p {
    padding: 30px 0 39px;
  }

  .trending-sec .client-detail-img {
    padding-top: 30px;
  }

  .registration-process {
    padding-bottom: 80px;
  }

  .ielts-main-sec p {
    font-size: 21px;
  }

  .official-sec-link a {
    font-size: 16px;
  }

  .counter-components div {
    margin: 10px 0;
  }

  .office-details-services .client-details p {
    margin: 0;
  }

  .office-details-services .client-details p {
    line-height: 22px;
    font-size: 15px;
  }

  .opportunity-small-card {
    padding: 50px 0 70px;
  }

  .top-uni-logo img {
    max-width: 130px;
    min-height: 142px;
  }

  .university-logos img {
    min-height: 109px;
  }

  .header-open-inner-sec {
    width: 43%;
  }

  .header-open-menu .accordion-body h5 {
    font-size: 16px;
  }

  .about-who-we-are .who-we-are-details {
    padding-top: 0;
  }

  .who-we-are-sec.about-who-we-are {
    padding: 0px 0 50px;
  }

  .all-team-member {
    padding: 50px 0;
  }

  .team-introduction p {
    font-size: 14px;
    padding-bottom: 7px;
    line-height: 18px;
  }

  .all-team-member .accordion-main-sec .accordion-button {
    font-size: 15px;
  }

  .book-a-free-consaltation h3 {
    font-size: 16px;
  }

  .all-team-member .directore-img {
    margin-bottom: 30px;
  }

  .about-who-we-are .we-are-img img {
    margin-bottom: 20px;
  }

  .banner-img {
    padding-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .sc-media-icons ul {
    margin-top: 0;
  }

  .bottom-menu .navbar-toggler {
    right: 5%;
    top: -51px;
  }

  .about-page-lead-section h2 {
    font-size: 2rem;
  }

  .scholarship-padding {
    padding-top: 20px;
  }

  .scholership-banner .country-banner-text h1 {
    font-size: 50px;
  }

  .university-list-sec .accordion-main-sec .accordion-button {
    font-size: 16px;
  }

  .navbar-brand img {
    max-width: 163px;
  }

  .banner-img h1 {
    font-size: 37px;
    line-height: 47px;
  }

  .banner-img p {
    padding-top: 20px;
  }

  .banner-btn a {
    margin-right: 15px;
  }

  .banner-img {
    height: 80vh;
  }

  .client-say {
    padding: 80px 0;
  }

  .client-detail-img {
    padding-top: 70px;
  }

  .client-say .client-detail-img {
    padding-top: 0px;
  }

  .client-details h4 {
    font-size: 19px;
  }

  .client-img {
    margin-bottom: -60px;
  }

  .client-img img {
    max-width: 100px;
  }

  .client-details {
    padding: 81px 15px 25px;
  }

  .all-need-img img {
    max-width: 240px;
  }

  .quote-img img {
    max-width: 30px;
  }


  .all-needs-part .row.flex-direction-mobile {
    flex-direction: column-reverse;
  }

  .all-need-img.left-align {
    text-align: center;
  }

  .directore-img {
    text-align: center;
  }

  .directore-img img {
    margin-top: 11px;
    max-width: 50%;
  }

  .university-logos img {
    max-width: 80px;
    min-height: 79px;
  }

  .title-sec h2 {
    font-size: 26px;
    padding-left: 11px;
  }

  .directore-inspired-details h4 {
    font-size: 18px;
    padding-bottom: 0;
  }

  .apply-margin-bottom {
    margin-bottom: 20px;
  }

  .country-banner-text h1 {
    font-size: 50px;
  }

  .first-menu-list {
    margin-bottom: 20px;
  }

  .canada-card img {
    max-width: 250px;
  }

  .canada-card {
    padding-right: 35px;
  }

  .banner-btn h2 {
    font-size: 25px;
  }

  .directore-inspired-details .pb-5 {
    padding-bottom: 20px !important;
  }

  .top-uni-logo img {
    max-width: 178px;
  }

  .rating-user-history {
    max-width: 280px;
    margin: 0 auto;
  }

  .mobile-view-margin {
    margin-bottom: 25px;
  }

  .admission-tab-menu ul li {
    margin-right: 30px;
  }



  .navbar-brand {
    padding-bottom: 0;
  }

  .sc-media-icons ul li a {
    display: flex;
    padding-bottom: 0;
    font-size: 14px !important;
  }

  .sc-media-icons.mobile-icons .btn.btn-comman {
    margin-top: 20px;
  }

  a.close-btn {
    font-size: 25px;
  }

  .country-list-banner.study-board {
    height: 80vh;
  }

  /* .country-banner-text {
      height: 70vh;
    } */

  .ielts-main-sec p {
    padding-top: 20px;
  }

  .official-sec-link a {
    font-size: 16px;
  }

  .official-websites-links {
    padding: 22px 0 40px;
  }

  .ielts-main-sec {
    padding: 50px 0 20px;
  }

  .websites-link-card h4 {
    padding-top: 7px;
    text-align: left;
  }

  .download-guide {
    text-align: center;
  }

  .download-guide img {
    max-width: 360px;
    margin: 0 auto;
  }

  .must-have-text p {
    padding-left: 20px;
  }

  /* .counter-components div {
      margin: 10px;
    } */

  .counter-components {
    flex-direction: row;
  }

  .accordion-main-sec .accordion-body {
    font-size: 14px;
  }

  .accordion-main-sec .accordion-button {
    font-size: 15px;
    line-height: 21px;
  }

  .mobile-mb-general {
    margin-bottom: 20px;
  }

  .how-to-transglobe-can-sec p {
    padding: 30px 0 30px;
    font-size: 15px;
  }

  .studern-services-card {
    padding-top: 40px;
  }

  .card-gradiont h4 {
    font-size: 21px;
  }

  .student-services-sec {
    padding-bottom: 20px;
  }

  .affiliat-logo {
    margin: 20px 0;
  }

  .affiliat-img {
    padding-top: 43px;
  }

  .book-form .form-control {
    padding: 8px 16px;
  }

  .book-form .form-select {
    padding: 8px 16px;
  }

  .service-right-img {
    text-align: center;
  }

  .service-right-img img {
    max-width: 300px;
  }

  .mb-view-mb {
    margin-bottom: 20px;
  }

  /* .contact-us-banner {
      height: 80vh;
    } */

  .director-flex {
    flex-direction: column;
  }

  .blur-bg {
    margin-top: -57px;
    max-width: 315px;
  }

  .office-details .banner-img h1 {
    font-size: 39px;
    line-height: 54px;
  }

  .office-details .banner-img p {
    max-width: 348px;
  }

  .vetaran-sec h5 {
    font-size: 20px;
  }

  .vetaran-sec h6 {
    font-size: 15px;
    line-height: 24px;
    padding: 20px 0 30px;
  }

  .office-details-services .client-detail-img {
    padding-top: 40px;
  }

  .office-details-services .client-details {
    min-height: auto;
  }

  .counter-path-bg {
    background-size: contain;
  }

  .office-counter {
    background-size: cover;
  }

  .office-counter-responsive {
    margin-bottom: 20px;
  }

  .office-counter-responsive:last-child {
    margin-bottom: 0;
  }

  .blog-main-sec.event-main-sec .country-card-list .client-details h4 {
    font-size: 20px;
  }

  .blog-main-sec .country-card-list .client-details h4 {
    font-size: 20px;
  }

  .canada-blog-content h2 {
    font-size: 25px;
    padding: 18px 0 19px;
  }

  .canada-blog-content p {
    font-size: 14px;
    line-height: 22px;
  }

  .blog-details-question {
    font-size: 17px;
  }

  .canada-blog-content ul li {
    font-size: 14px;
  }

  .testimonial-video iframe {
    height: 305px;
  }

  .subscribe-input-field input {
    min-width: 350px;
  }

  .newsletter-base p {
    font-size: 16px;
    padding: 30px 0 30px;
  }

  .director-content-sec {
    text-align: center;
    padding-right: 0;
  }

  .directore-inspired-details p {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .about-us-banner {
    height: 80vh;
  }

  .who-we-are-details p {
    font-size: 14px;
    padding-bottom: 11px;
  }

  .why-choose-left-content.being-member h2 {
    padding: 18px 0;
    font-size: 19px;
  }

  .why-choose-left-content.being-member p {
    font-size: 14px;
  }

  .why-choose-left-content.being-member ul li {
    font-size: 14px;
  }

  .why-choose-left-content.being-member ul li img {
    max-width: 14px;
  }

  .who-we-are-sec {
    padding: 40px 0 16px;
  }

  .all-team-member {
    text-align: center;
    padding: 60px 0;
  }

  .team-introduction p {
    padding: 9px 0 0;
  }

  .team-introduction a {
    padding-top: 20px;
    display: inline-block;
  }

  .all-team-member.bg-transparent .row {
    flex-direction: column-reverse;
  }

  .benefits-main-sec.benefits-of-usa .card-first {
    min-height: auto;
  }

  .benefits-define .client-details {
    min-height: auto;
  }

  .benefits-main-sec {
    padding: 20px 0 0px;
  }

  .trending-sec .client-detail-img {
    padding-top: 50px;
  }

  .opportunities .directore-inspired-details p {
    text-align: center;
  }

  .admission-tabs {
    padding-top: 10px;
  }

  .visa-option {
    padding-left: 0;
    text-align: center;
  }

  .opportunity-small-card {
    padding: 40px 0 70px;
  }

  .explore-flag-card {
    padding: 50px 0 90px;
  }

  .benefits-main-sec.benefits-of-uk .client-details {
    min-height: auto;
    padding: 35px 15px;
  }

  .singapore-benefits .benefits-define .client-details {
    min-height: auto;
  }

  .benefits-main-sec .card-first {
    min-height: auto;
  }

  .card-gradiont {
    text-align: center;
    padding: 20px 15px;
  }

  .card-gradiont p {
    text-align: center;
  }

  .ielts-main-sec {
    text-align: center;
  }

  .why-choose-left-content h2 {
    padding-bottom: 19px;
    font-size: 21px;
  }

  .new-zealand-benefits .benefits-main-sec .card-first .client-details {
    min-height: auto;
  }

  p.site-red-font {
    padding-bottom: 20px;
  }

  .repsonsive-directore-desk .director-flex {
    flex-direction: column-reverse;
  }

  .repsonsive-directore-desk .director-content-sec {
    padding-top: 20px;
  }

  .set-animated-padding-left {
    padding-left: 15px;
  }

  .scholarship-card .swiper-container {
    padding: 10px 0;
  }

  .all-need-img {
    margin-bottom: 20px;
  }

  .all-need-data {
    margin-bottom: 20px;
  }

  .contact-modal .row {
    flex-direction: column-reverse;
  }

  .contact-modal .service-right-img img {
    max-width: 200px;
  }

  .header-open-inner-sec {
    width: 53%;
  }

  .sc-media-icons.mobile-icons {
    padding-top: 10px;
  }

  .directore-desk {
    padding: 70px 0;
  }

  .about-who-we-are .who-we-are-details {
    text-align: center;
  }

  .about-who-we-are .who-we-are-top p {
    font-size: 14px;
  }

  .about-list-sec li {
    font-size: 14px;
  }

  .how-to-Trans.Globe-can-sec.services-contact {
    padding-top: 60px;
  }

  .directore-desk.why-study-canada {
    padding: 30px 0;
  }

  .all-citys-spacing {
    padding-top: 20px;
  }

  .admission-tab-menu li {
    margin-bottom: 10px;
  }

  .client-details video {
    width: 360px;
  }

  .who-we-are-details {
    padding-top: 0;
  }

  .career-acc h2 {
    font-size: 20px;
  }

  .sub-title-career h5 {
    font-size: 17px;
  }

  .scholarships-cards .intake-card {
    margin-bottom: 15px;
    width: 100%;
  }

  .scholarships-cards {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
  }

  .testimonial-cards {
    padding: 60px 0 70px;
  }

  .testimonial-detail-img .card-first {
    margin-bottom: 30px;
    padding: 15px;
  }

  .quote-img {
    margin-top: 14px;
  }

  .testimonial-detail-img .client-details {
    padding: 20px 0 0;
  }

  .header-open-menu .accordion-button {
    font-size: 15px;
  }

  .all-country-cards.blog-main-sec {
    padding: 60px 0;
  }

  .intakes-main-sec .intake-card {
    height: auto;
  }

  .copy-right h5 {
    text-align: center;
  }

  .all-need-img {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .country-list-card .scholarship-details {
    padding: 9px 15px;
  }

  .intakes-main-sec .intake-card {
    justify-content: flex-start;
  }

  .general-fact-list p {
    font-size: 14px;
    line-height: 24px;
  }

  .who-we-are-sec.scholership-bur {
    padding: 40px 0 0;
  }

  .who-we-are-sec.uk-scholartship {
    padding: 30px 0 0;
  }

  .who-we-are-sec.australia-scholership {
    padding: 50px 0;
  }

  .scholership-banner .country-banner-text h1 {
    font-size: 40px;
  }

  .banner-img h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .banner-img p {
    font-size: 14px;
    line-height: 22px;
  }

  .banner-btn {
    padding-top: 30px;
  }

  .banner-btn a {
    margin-bottom: 20px;
    margin-right: 5px;
    font-size: 13px;
  }

  .title-sec h2 {
    font-size: 20px;
    padding-left: 8px;
    text-align: left;
  }

  .title-sec a {
    font-size: 13px;
  }

  .client-img img {
    max-width: 90px;
  }

  .client-img {
    margin-bottom: -41px;
  }

  .client-details {
    padding: 61px 15px 25px;
  }

  .client-details h4 {
    font-size: 16px;
  }

  .client-details span {
    font-size: 10px;
  }

  .quote-img img {
    max-width: 37px;
  }

  .client-details p {
    font-size: 13px;
    padding: 14px 0;
  }

  .client-details a {
    font-size: 13px;
  }

  .all-need-data h3 {
    font-size: 20px;
  }

  .all-need-data p {
    font-size: 15px;
    line-height: 23px;
  }

  .all-need-data a {
    font-size: 13px;
    padding: 4px 8px;
  }

  .scholarship-card p {
    font-size: 16px;
  }

  .all-country-cards {
    padding: 50px 0;
  }

  .study-in-canada-main {
    flex-direction: column;
    text-align: center;
  }

  .canada-card {
    padding-right: 0;
  }

  a.btn.download-btn {
    font-size: 12px;
    padding: 4px 8px;
  }

  .scholarship-sec.country-list-card .scholarship-details img {
    max-width: 60px;
  }

  .scholarship-sec.country-list-card .scholarship-details h3 {
    font-size: 15px;
    line-height: 21px;
  }

  .how-to-apply-sec p {
    font-size: 14px;
  }

  .admision-title {
    font-size: 18px;
  }

  .program-course-icon {
    padding-right: 8px;
  }

  .program-course-icon img {
    max-width: 34px;
  }

  .popular-program-sec .opportunity-text p {
    font-size: 12px;
  }

  .opportunity-card {
    padding: 6px 8px;
  }

  .university-card-bg img {
    max-width: 79px;
    padding: 0;
  }

  .university-card-bg {
    min-height: 114px;
  }

  .visa-option {
    padding: 40px 0px 20px 0px;
  }

  .visa-option h3 {
    font-size: 21px;
  }

  .post-admission-details h4 {
    font-size: 14px;
  }

  .post-admission-details p {
    font-size: 13px;
  }

  .post-admission {
    padding: 70px 0;
  }

  .opportunity-small-card {
    padding: 40px 0 30px;
  }

  .opportunity-text p {
    font-size: 15px;
  }

  .opportunity-icon {
    margin-right: 12px;
  }

  .opportunity-icon img {
    max-width: 26px;
  }

  .country-card-list .client-details h4 {
    font-size: 18px;
  }

  .admission-tab-menu ul li {
    margin-right: 22px;
  }

  .admission-tab-menu ul li a {
    font-size: 20px;
  }

  .ielts-main-sec {
    text-align: center;
  }

  .ielts-main-sec img {
    max-width: 130px;
  }

  .ielts-main-sec p {
    font-size: 17px;
  }

  .official-sec-link {
    text-align: center;
  }

  .websites-link-card {
    text-align: center;
  }

  .websites-link-card h3 {
    font-size: 18px;
  }

  .about-the-test h3 {
    font-size: 19px;
  }

  .about-ielts-exam-sec {
    padding-bottom: 50px;
  }

  .eligibility-h4 {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .must-have-text p {
    padding-left: 16px;
    font-size: 16px;
  }

  .must-have-text img {
    max-width: 83px;
  }

  .listing-components-card h5 {
    font-size: 16px;
  }

  .counter-dataimg img {
    max-width: 48px;
  }

  .free-cards {
    padding: 17px;
  }

  .free-cards h4 {
    padding: 9px 0 3px;
    font-size: 14px;
  }

  .free-cards h3 {
    font-size: 17px;
  }

  .component-main-sec {
    padding: 60px 0 30px;
  }

  .registration-process p {
    font-size: 15px;
    line-height: 24px;
  }

  .card-gradiont h4 {
    font-size: 19px;
  }

  .card-gradiont p {
    padding-top: 8px;
  }

  .btn-apply {
    width: 100%;
  }

  .virtual-data {
    text-align: center;
    flex-direction: column;
    top: 30%;
  }

  .virtual-data p {
    font-size: 15px;
  }

  .btn-white {
    font-size: 12px;
    padding: 4px 10px;
    max-width: 130px;
    margin: 10px auto;
  }

  .home-address {
    padding: 29px 0 0 21px;
  }

  .home-address p {
    font-size: 15px;
  }

  .home-address i {
    font-size: 17px;
  }

  .university-logos img {
    max-width: 46px;
    padding: 10px 0;
    min-height: 39px;
  }

  .blur-bg h4 {
    font-size: 16px;
  }

  .blur-bg {
    margin-top: -57px;
    max-width: 167px;
  }

  .directore-inspired-details h4 {
    font-size: 16px;
  }

  .scholarship-details img {
    max-width: 54px;
    margin-right: 14px;
  }

  .scholarship-details h5 {
    font-size: 14px;
  }

  .scholarship-details h3 {
    font-size: 13px;
    line-height: 14px;
  }

  .blur-bg h5 {
    font-size: 11px;
  }

  .admission-tab-menu li {
    margin-right: 15px;
  }

  .admission-tab-menu {
    padding-top: 30px;
  }

  .admission-tab-menu li:last-child {
    margin-right: 0;
  }

  .admission-tab-menu li .nav-link {
    font-size: 16px;
  }

  .banner-img {
    height: 75vh;
  }

  .office-details .banner-img h1 {
    font-size: 32px;
    line-height: 43px;
  }

  .office-details .banner-img p {
    max-width: 299px;
    font-size: 16px;
    line-height: 27px;
  }

  .how-to-transglobe-can-sec {
    padding: 30px 0;
  }

  .vetaran-sec h5 {
    font-size: 18px;
  }

  .why-choose-left-content h2 {
    font-size: 20px;
    padding-bottom: 24px;
  }

  .why-choose-left-content ul li img {
    max-width: 16px;
    margin-right: 12px;
  }

  .why-choose-left-content ul li {
    font-size: 15px;
  }

  .office-details-services .client-details h4 {
    font-size: 19px;
  }

  .office-details-services .client-details p {
    padding: 8px 0 0;
    font-size: 15px;
    line-height: 22px;
  }

  .canada-blog-content h2 {
    font-size: 21px;
  }

  .canada-blog-content p {
    padding-bottom: 14px;
  }

  .blog-details-question {
    font-size: 18px;
    line-height: 26px;
  }

  .canada-blog-content ul li {
    font-size: 14px;
  }

  .by-trans-globe h6 {
    font-size: 14px;
  }

  .latest-blog-list p {
    font-size: 14px;
  }

  .latest-blog-title {
    font-size: 21px;
  }

  .testimonial-video iframe {
    height: 195px;
  }

  .subscribe-input-field {
    flex-direction: column;
  }

  .btn.btn-subscribe {
    margin-left: 0;
    margin-top: 30px;
  }

  .testimonial-video-sec {
    padding: 50px 0;
  }

  .btn.btn-subscribe {
    font-size: 16px;
  }

  .book-form .mb-4 {
    margin-bottom: 1rem !important;
  }

  .book-a-free-consaltation h3 {
    text-align: center;
    font-size: 17px;
  }

  #PopularProgramsAccordion .accordion-body ul li {
    font-size: 14px;
  }

  .directore-desk.why-study-canada .directore-inspired-details {
    padding-top: 30px;
  }

  .benefits-main-sec p {
    padding-bottom: 10px;
  }

  .benefits-main-sec p {
    padding-bottom: 10px;
  }

  .benefits-main-sec .client-details p {
    margin: 14px 0 0;
  }

  .benefits-main-sec .client-details h4 {
    font-size: 18px;
  }

  .directore-desk {
    padding: 50px 0;
  }

  .university-partners.top-universitys .client-detail-img {
    padding-top: 40px;
  }

  .top-uni-logo img {
    max-width: 188px;
    min-height: 154px;
  }

  .university-partners.top-universitys {
    padding: 50px 0 70px;
  }

  .trending-sec .benefits-main-sec .client-details {
    min-height: auto;
    padding: 21px 10px;
  }

  .trending-sec .client-details {
    min-height: 230px;
  }

  .admission-req-main .scholarship-card {
    padding-top: 40px;
  }

  .how-to-apply-sec p {
    padding-bottom: 20px !important;
  }

  .explore-flag-card {
    padding: 50px 0 60px;
  }

  .client-say {
    margin: 30px 0 0;
  }

  .scholarship-padding {
    padding-top: 20px;
  }

  .newsletter-base h2 {
    font-size: 24px;
  }

  .newsletter-base p {
    padding: 20px 0 30px;
  }

  .card-gradiont p {
    font-size: 14px;
  }

  .general-fact-list .mb-4 {
    margin-bottom: 13px !important;
  }

  .listing-components .swiper.swiper-components {
    padding: 36px 19px;
  }

  .client-say.pt-0.trending-sec.office-details-services .client-details {
    min-height: auto;
  }

  .university-partners {
    padding: 20px 0 70px;
  }

  p.site-red-font {
    padding-bottom: 0;
  }

  .book-a-free-consaltation h3 {
    padding-bottom: 20px;
  }

  .contact-modal .book-form .form-control::placeholder {
    font-size: 12px;
  }

  .contact-modal .book-form .form-select::placeholder {
    font-size: 11px;
  }

  .open-menu ul li a {
    font-size: 16px;
  }

  .header-open-inner-sec {
    width: 79%;
  }

  .all-team-member .directore-img {
    margin-bottom: 10px;
  }

  .join-our-parent h4 {
    font-size: 18px;
  }

  .career-form a {
    display: block;
    margin-bottom: 10px;
  }

  .team-member-name h5 {
    font-size: 16px;
  }

  .country-banner-text h1 {
    font-size: 15px;
  }

  .latest-blog-img img {
    width: 130px;
  }

  .book-form {
    padding-top: 0;
  }

  .why-choose-left-content.being-member ul {
    padding-left: 0;
  }

  .scholarship-sec.country-list-card {
    padding: 40px 0 20px;
  }

  .career-emp-service-text {
    padding-top: 30px;
  }

  .career-emp-service-text h3 {
    font-size: 20px;
  }

  .carousel-indicators {
    margin-bottom: 0;
  }

  .directore-desk.bg-transparent.repsonsive-directore-desk.about-trans-globe-banner {
    padding: 50px 0;
  }

  .about-trans-globe-banner .directore-inspired-details {
    padding-top: 0;
  }

  .university-partners .swiper.swiper-university-partners {
    padding: 20px 0 40px;
  }

  .client-say .our-clients-say .client-details {
    min-height: 380px;
  }

  .first-menu-list ul li {
    text-align: left;
  }

  .first-menu-list h5 {
    text-align: left;
  }
}

.menu-list-location {
  position: relative;
}

.menu-nav-bar {
  display: none;
}

.menu-list-location:hover .menu-nav-bar {
  display: block;
  position: absolute;
  right: 0;
  top: 100%;
  width: 200px;
  background: #c9ffe4;
  padding: 10px;
  z-index: 1000;
  border-radius: 7px;
}

.menu-list-location:hover .menu-nav-bar a {
  display: block;
  border-bottom: 1px solid #e7b3b363;
  padding: 5px 0;
  color: #000;
  text-align: center;
}
.breadcrumb-wrapper {
    background: #f5f5f5;
    margin-bottom: 30px;
}

.breadcrumb-wrapper ul {
    display: flex;
}

.breadcrumb-wrapper ul li {
    margin-right: 15px;
    padding: 10px 0;
}

.contact-highlights {
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    font-size: 20px;
    line-height: 2;
}

.contact-highlights h3 {
    font-size: 38px;
    margin: 0 0 10px;
}

.contact-highlights  p.cta {
    font-size: 17px;
    background: #f5f5f5;
    margin: auto;
    display: inline-block;
    padding: 10px;
}

.contact-address h3 {
    font-size: 30px;
    margin: 0 0 30px;
    text-align: center;
}