.field-require{
  color: red;
}

.fields{
  display: grid;
  padding-inline: 10rem;
  padding-top: 5rem;
}

input, textarea{
  border-radius: 0.3rem;
  border-color: rgba(0, 0, 0, 0.37);
  border-style: solid;
  border-width: 0.09rem;
  height: 2.4rem;
  textarea {
    height: 4rem;
  }
}

label{
  margin-bottom: 0.2rem;
}

.radios{
  display: flex;
}

.form-desc{
  color: rgb(93, 93, 93);
}

.radioText{
  padding-top: .4rem;
  padding-left: 0.6rem;
}

.submit{
  width: 10rem;
  background: color(srgb 0.8065 0.945 0.7418);
}

.form-address{
  height: 4rem;
}